  .firebaseui-container.firebaseui-id-page-sign-in {
    background: transparent;
    box-shadow: none;
    min-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    width:40vh;
  }
  .firebaseui-container.firebaseui-id-page-password-sign-up {
    background: transparent;
    box-shadow: none;
    min-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    width:40vh;
  }
  .firebaseui-card-header{
    display: none;
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 16px;
    padding: 16px 24px 0;
  }
  .firebaseui-form-actions{
    display: table-cell;
    text-align: right;
    white-space: nowrap;
    width: 100%;
  }